import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Areas of uncertainty`}</h1>
    <p>{`Section of the `}<a parentName="p" {...{
        "href": "/report",
        "title": "report"
      }}>{`report`}</a>{` with statements on which greater than 30% of participants who saw them 'passed'. See `}<a parentName="p" {...{
        "href": "/pass",
        "title": "pass"
      }}>{`pass`}</a>{`.
Areas of uncertainty can provide avenues to educate and open dialogue with your community.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      